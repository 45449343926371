<template>
	<div>
		<el-card class="filter-container" shadow="never">
            <el-form ref="form" :model="form" :rules="dataRule" label-width="150px">
				<el-form-item label="文章所属主栏目"  prop="newsGroupId">
					<el-select v-model="form.newsGroupId" placeholder="文章所属主栏目" style="width: 30%;" value-key="menuName">
						<el-option v-for="item in typesData" :key="item.id" :label="item.menuName" :value="item.id"></el-option>
					</el-select>

				</el-form-item>


                <el-form-item label="文章名称" prop="newsTitle">
                    <el-input v-model="form.newsTitle" style="width: 30%;"></el-input>
                </el-form-item>

                <el-form-item label="简短标题">
                    <el-input v-model="form.newsTitleShort" style="width: 30%;"></el-input>
                </el-form-item>

				<el-form-item label="封面上传">
					<el-upload class="upload-demo" :action="uploadImgUrl" style="width: 30%;"
					 :on-success="onsuccess" list-type="picture" :multiple="multiple" :limit="1" :on-exceed="onexceed" :file-list="fileList"
					 :on-remove="onremove">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>

				<el-form-item label="是否启用">
					<el-switch v-model="form.newsOpen"></el-switch>
				</el-form-item>

                <el-form-item label="排序" prop="listorder">
                    <el-input v-model="form.listorder" style="width: 30%;" onkeyup="value=value.replace(/[^\d]/g,'')" onafterpaste="value=value.replace(/\D/g,'')"></el-input>
                    <font color="red" style="margin-left: 10px">请输入整型数字!!</font>
                </el-form-item>

				<el-form-item label="显示日期">
					<el-date-picker
					      v-model="form.showDate"
					      type="date"
					      placeholder="选择日期" style="width: 30%;">
					    </el-date-picker>
				</el-form-item>


				<el-form-item label="文章简介">
					<el-input v-model="form.newsScontent" style="width: 30%;"></el-input>
				</el-form-item>

				<el-form-item label="文章内容">
					<!-- <el-input v-model="form.desc" style="width: 30%;"></el-input> -->
					<div id="div1"></div>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onSubmit">确定</el-button>
					<el-button type="info" @click="gobreak">取消</el-button>
				</el-form-item>
			</el-form>
		</el-card>

	</div>


</template>
<script>
	import E from "wangeditor";
	export default {
		data() {
			return {
                form: {
                    newsId: '',
                    newsGroupId: '', //文章类型编号
                    newsTitle: '', //文章标题
                    newsTitleShort: '', //简短标题
                    newsFlag: '', //文章属性字符串
                    newsCpPrice: '', //属性为产品
                    newsZaddress: '', //属性为跳转
                    newsKey: '', //文章关键字
                    newsSource: '未知来源', //文章来源
                    newsPicType: '', //图片标志
                    newsPicContent: '', //图片描述
                    listorder: '', //文章排序
                    showDate: '', //显示日期
                    newsScontent: '', //文章简介
                    newsContent: '', //文章主内容
                    newsOpen: true, //是否审核
                    newsTag: '1', //文章标签
                    newsImg: '', //图片url
                    newsPicAllUrl: '', //多图url
                },
				typesData: [], //文章栏目列表
				attrData: [], //文章属性列表
				sourceData: [], //文章来源列表
				multiple: false, //多文件上传
				userId: '', //作者编号
				query: [], //接的get参数
				newsZShow: false, //
				resource: '', //多图
				// 图片列表
				fileList: [],

				uploadImgUrl: '', // 图片上传路径
                dataRule: {
                    newsGroupId: [
                        { required: true, message: '文章所属主栏目不能为空', trigger: 'change' }
                    ],
                    newsTitle: [
                        { required: true, message: '文章名称不能为空', trigger: 'blur' }
                    ],
                    listorder: [
                        { required: true, message: '排序不能为空', trigger: 'blur' }
                    ]
                },

			}
		},
		methods: {
			// 提交
			onSubmit() {
				const that = this;
                that.form.newsGroupId = (that.form.newsGroupId).toString(), //文章类型编号
                that.form.newsOpen = that.form.newsOpen == true ? '1' : '0', //是否审核

                that.$refs['form'].validate((valid) => {
                    if (valid) {
                        if( that.query.nid ){
                            var apiurl='/admin/news/edit';
                            that.form.newsId=that.query.nid;
                        }else{
                            var apiurl='/admin/news/add';
                        }
                        that.api(apiurl, that.form).then(res => {
                            if(res.code==200){
                                // this.$router.go(-1);//返回上一层
                                that.$router.replace({name:'newslist'})

                            }
                        })
                    }
                })
			},
			//显示跳转
			goTo(e) {
				if (e.indexOf("j") > -1) {
					this.newsZShow = true;
				} else {
					this.newsZShow = false;
				}

			},
			// 返回上一页
			gobreak(){
				const that=this;
				this.$router.go(-1);//返回上一层
			},

			// 图片上传成功
			onsuccess(file, fileList) {
				const that = this;
				that.form.newsImg=file.data.filePath;
				
			},


			// 点击删除
			onremove(file, fileList) {
				const that = this;
				that.form.newsImg='';
			},

			// 图片上传超出
			onexceed(e) {
				const that = this;
                that.$message({
					showClose: true,
					message: '数量超出限制',
					type: 'warning'
				});

			},

			//获取修改文章的数据
			getArticle() {
				const that = this;
				that.api('/admin/news/getEditItem', {
					nid: that.query.nid
				}).then(res => {
				    that.form = res.data.news;
                    that.form.newsGroupId = Number(res.data.news.newsGroupid);
                    that.form.newsOpen = res.data.news.newsOpen == '1' ? true : false;
                    that.form.newsTitleShort = res.data.news.newsTitleshort;
                    that.form.showDate = res.data.news.newsTime;
					if(res.data.news.newsImg!=''){
						var imgshow={
							url:that.$utils.apiurl+res.data.news.newsImg
						}
						
						that.fileList.push(imgshow)
					}

					that.editor.txt.html(that.form.newsContent);
				})
			},
			//获取添加文章时需要的栏目分类，文章属性，文章来源字段
			getAttrs() {
				const that = this;
				that.api('/admin/news/getAddItem').then(res => {
					that.attrData = res.data.diyFlagList;
					that.sourceData = res.data.sourceList;
				});
				that.api('/admin/menu/list').then(res => {
					that.typesData = res.data;
				});

			},




		},
		created() {
			const that = this;
			that.query = that.$route.query;
			that.uploadImgUrl = that.$utils.uploadimgurl;
			that.getAttrs();
			if (that.query.nid) {
				that.getArticle();
			};


		},
		mounted() {
			const that = this;
			that.editor = new E("#div1");
			that.editor.config.onchange = function(newHtml) {
                that.form.newsContent = newHtml;
			};
			that.editor.config.uploadImgMaxLength = 1;
			// that.editor.config.uploadImgServer = "http://bzjj.hrbcft.com/api/index/up1";
			// that.editor.config.uploadFileName = "upfile";
			// 接口地址 key
			that.editor.config.uploadImgServer = that.$utils.uploadimgurl;
			that.editor.config.uploadFileName = "file";

			that.editor.config.uploadImgHooks = {
				// 图片上传并返回了结果，想要自己把图片插入到编辑器中
				// 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
				customInsert: function(insertImgFn, result) {
					// result 即服务端返回的接口
					var img = that.$utils.apiurl + result.data.filePath
					// insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
					insertImgFn(img)
				}
			}
			that.editor.create();

		},

		beforeDestroy() {
			const that = this;
			// 销毁编辑器
			that.editor.destroy()
			that.editor = null
		},
	}
</script>

<style scoped lang="less">
</style>
